import React from 'react';
import { staticPagesPath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/backOffice/staticPages')
);
const layout = React.lazy(() => import('components/adminLayout'));
export default {
  layout,
  path: staticPagesPath(),
  exact: true,
  strict: true,
  component: component,
  protected: true,
  role: ['admin', 'superAdmin']
};
