import { onError } from '@apollo/client/link/error';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
  HttpLink,
  from,
  split
} from '@apollo/client';

import { API_HOST, AUTH_TOKEN } from 'constants/API';
import customTypes from 'graphql/types/imageAttributes.graphql';

import { createConsumer } from '@rails/actioncable';
import { ActionCableLink } from 'graphql-ruby-client';

import notifications from 'components/notifications';
import { initData } from 'components/provider';

export const createCache = () => {
  const cache = new InMemoryCache({
    // freezeResults: true,
    // cacheRedirects: {
    //   Query: {
    //     exercise: (_, args, { getCacheKey }) => {
    //       return getCacheKey({ __typename: 'Exercise', id: args.id });
    //     },
    //     user: (_, args, { getCacheKey }) => {
    //       return getCacheKey({ __typename: 'User', id: args.id });
    //     },
    //     service: (_, args, { getCacheKey }) => {
    //       return getCacheKey({ __typename: 'Service', id: args.id });
    //     }
    //     // cacheConversation: (_, args, { getCacheKey }) => {
    //     //   return getCacheKey({ __typename: 'Conversation', id: args.id });
    //     // }
    //   }
    // }
  });
  return cache;
};

const serialize = obj =>
  Object.entries(obj)
    .map(i => [i[0], encodeURIComponent(i[1])].join('='))
    .join('&');

const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    ({ kind, operation }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  );

const generateURL = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const host = process.env.REACT_APP_CABLE_HOST || window.location.hostname;
  const port = process.env.REACT_APP_CABLE_PORT;
  return `${protocol}//${host}${port === '' ? '' : `:${port}/cable`}`;
};

const createActionCableLink = () => {
  const cable = createConsumer(generateURL());
  const consumer_url = cable.url;
  Object.defineProperty(cable, 'url', {
    get: () => `${consumer_url}?${serialize(getTokens())}`
  });
  return new ActionCableLink({ cable });
};

export const getTokens = () => {
  const raw = localStorage.getItem(AUTH_TOKEN);
  return raw ? JSON.parse(raw) : {};
};

const setTokenForOperation = async (operation, b, c) => {
  const { operationName } = operation;
  const uri = getUri({ operationName });
  return operation.setContext({
    headers: { ...getTokens(), app: 'frontend' },
    uri
  });
};

// const createLinkWithToken = () =>
//   new ApolloLink(
//     (operation, forward) => {
//       const context = operation.getContext();
//       const controller = new AbortController();
//       const { operationName } = operation;
//       const uri = getUri({ operationName });
//       operation.setContext({
//         ...context,
//         controller,
//         fetchOptions: {
//           signal: controller.signal
//         },
//         headers: { ...getTokens() },
//         uri
//       });
//
//       const after = operation.getContext();
//       if (!localStorage.getItem(AUTH_TOKEN) && context.abortPreviousId) {
//         after.controller.abort();
//       }
//
//       return forward(operation);
//     }
//   );

const createLinkWithToken = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        const context = operation.getContext();
        const controller = new AbortController();
        operation.setContext({
          ...context,
          controller,
          fetchOptions: {
            signal: controller.signal
          }
        });

        Promise.resolve(operation)
          .then(setTokenForOperation)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

        const after = operation.getContext();

        if (!localStorage.getItem(AUTH_TOKEN) && context.abortPreviousId) {
          after.controller.abort();
        }

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

const logError = (error, { operationName, errors }) => {
  if (errors && errors.length > 0 && operationName !== 'IntrospectionQuery') {
    errors.forEach(error => {
      let message = error;
      if (error.message) {
        message = error.message;
      } else if (error.fullMessages) {
        message = error.fullMessages;
      }
      notifications({ message, type: 'danger' });
    });
  }
};

const createErrorLink = cache =>
  onError(({ graphQLErrors, networkError, operation, response }) => {
    const keys = Object.keys(response?.data || {});
    keys.forEach(key => {
      if (response.data[key] === null) response.data[key] = {};
    });
    updateHeaders({ cache, operation });
    if (graphQLErrors) {
      logError('GraphQL - Error', {
        errors: graphQLErrors,
        operationName: operation.operationName,
        variables: operation.variables
      });
    }
    if (networkError) {
      logError('GraphQL - NetworkError', networkError);
    }
    if (networkError && networkError.statusCode === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      // cache.reset();
      const { currentUser, receivedMessage } = initData;
      cache.modify({
        fields: {
          isLoggedIn: () => {
            return false;
          },
          currentUser: () => {
            return currentUser;
          },
          receivedMessage: () => {
            return receivedMessage;
          }
        }
      });
    }
  });

const authOperation = [
  'UserLogin',
  'UserLogout',
  'UserSignUp',
  'UserResendConfirmation',
  'UserUpdatePassword',
  'UserSendPasswordReset',
  'UserResetPassword'
];

const getUri = ({ operationName }) => {
  return authOperation.includes(operationName)
    ? `${API_HOST}/api/v1/auth`
    : `${API_HOST}/api/v1/graphql`;
};

const createHttpLink = () =>
  new HttpLink({
    uri: `${API_HOST}/api/v1/graphql`,
    credentials: 'include'
  });

export const createClient = cache => {
  return new ApolloClient({
    link: from([
      createLinkWithToken(),
      afterwareLink(cache),
      createErrorLink(cache),
      split(hasSubscriptionOperation, createActionCableLink(), createHttpLink())
    ]),
    cache,
    resolvers: {},
    customTypes,
    connectToDevTools: process.env.NODE_ENV === 'development'
  });
};

const updateHeaders = ({ cache, operation }) => {
  const { response } = operation.getContext();
  if (!response) return;
  const { headers } = response;
  if (!headers) return;
  const accessToken = headers.get('access-token');
  if (accessToken) {
    const token = {
      'access-token': accessToken,
      client: headers.get('client'),
      uid: headers.get('uid')
    };
    cache.modify({
      fields: { isLoggedIn: () => true }
    });
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
  }
};

const afterwareLink = cache => {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      updateHeaders({ cache, operation });
      const { data = {} } = response;
      const key = Object.keys(data)[0];

      if (data[key].errors) {
        data[key].errors.fullMessages.forEach(error => {
          notifications({ message: error, type: 'danger' });
        });
      }
      return response;
    });
  });
};
