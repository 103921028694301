import React from 'react';
import { exercisePath } from 'helpers/routes';
const component = React.lazy(() => import('components/views/exercise/show'));
export default {
  path: exercisePath(),
  exact: true,
  strict: true,
  component: component,
  protected: true
};
