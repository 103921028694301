import React from 'react';
import { newRealityModuleOnePath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/newReality/modules/firstModule')
);
export default {
  path: newRealityModuleOnePath(),
  exact: true,
  strict: true,
  component: component
};
