import { store } from 'react-notifications-component';

export default ({ message = '', type = 'success', duration = 1500 }) => {
  store.addNotification({
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: { duration }
  });
};
