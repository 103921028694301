import React from 'react';
import { serviceAccountPath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/account/services/show')
);
export default {
  path: serviceAccountPath(),
  exact: true,
  strict: true,
  component: component,
  protected: true
};
