import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import SignIn from 'components/auth/signIn';
import SignUp from 'components/auth/signUp';
import ResetPassword from 'components/auth/resetPassword';

export const AuthMenu = React.memo(
  ({ handleOnClick = () => {}, callbackAfterSignUp = () => {} }) => {
    const [state, setState] = useState(0);
    const { t } = useTranslation();
    const toogle = newState => event => {
      event.preventDefault();
      setState(newState);
    };
    return (
      <>
        {state < 2 && (
          <ul
            data-uk-tab
            className="uk-nav-default text-left uk-nav custom mb-2"
          >
            <li className={`${state === 0 ? 'uk-active' : ''}`}>
              <a href="/" onClick={toogle(0)} className="mobile">
                {t('buttons.signIn')}
              </a>
            </li>
            <li className={`${state === 1 ? 'uk-active' : ''}`}>
              <a href="/" onClick={toogle(1)} className="mobile">
                {t('buttons.signUp')}
              </a>
            </li>
          </ul>
        )}
        {state === 0 && (
          <SignIn handleOnClick={handleOnClick} toogle={toogle} />
        )}
        {state === 1 && (
          <SignUp
            handleOnClick={handleOnClick}
            callbackAfterSignUp={callbackAfterSignUp}
          />
        )}
        {state === 2 && (
          <ResetPassword handleOnClick={handleOnClick} toogle={toogle} />
        )}
      </>
    );
  }
);

export default React.memo(
  ({
    label,
    handleSignOut,
    isLoggedIn,
    handleOnClick = () => {},
    callbackAfterSignUp = () => {}
  }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        {isLoggedIn ? (
          <DropdownToggle
            tag="button"
            className="btn btn-kirkova-white"
            onClick={handleSignOut}
          >
            {label}
          </DropdownToggle>
        ) : (
          <DropdownToggle tag="button" className="btn btn-kirkova">
            {label}
          </DropdownToggle>
        )}
        {!isLoggedIn && (
          <DropdownMenu right className="dropdown-menu-auth open">
            <AuthMenu
              handleOnClick={handleOnClick}
              callbackAfterSignUp={callbackAfterSignUp}
            />
          </DropdownMenu>
        )}
      </Dropdown>
    );
  }
);
