import React from 'react';
import loading from 'constants/images/loading-screen.gif';

export default React.memo(() => {
  return (
    <div className="vh-100 vw-100">
      <div className="loader-img">
        <img src={loading} alt="Loading content" />
      </div>
    </div>
  );
});
