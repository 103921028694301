import React from 'react';
import Link from 'helpers/Link';
import { rootPath } from 'helpers/routes';
import * as Sentry from '@sentry/browser';

const Layout = React.lazy(() => import('components/layout'));

const Details = () => {
  return (
    <Layout>
      <div className="padding-top-bottom-120px">
        <div className="container-spread">
          <div className="row">
            <div className="col-md-10 col-sm-12 col-xs-12 text-left">
              <h2 className="mb-0 text-weight-200 text-gray-extra-dark">
                На сайте что-то сломалось
              </h2>
              <h2 className="mb-0 text-weight-800 text-gray-extra-dark">
                сломалось.
              </h2>
              <div className="separator bottom-border border-1px border-color-gray-light width-10 margin-top-bottom-50px"></div>
              <h6 className="margin-bottom-35px open-sans text-weight-400 text-gray-extra-dark">
                Ошибка 500
              </h6>
              <p>Мы уже знаем об этой ошибке и скоро все починим.</p>
              <Link
                to={rootPath()}
                className="btn btn-extra-large btn-black margin-right-10px sm-display-table xs-margin-bottom-15px sm-margin-left-right-auto"
              >
                Вернуться на главную
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error.originalError || error);
      this.setState({ eventId });
    });
  }

  render() {
    return this.state.hasError ? <Details /> : this.props.children;
  }
}

export default ErrorBoundary;
