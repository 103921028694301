import React from 'react';
import useForm from 'helpers/useForm';
import { useTranslation } from 'react-i18next';
import signUpMutation from 'graphql/queries/auth/signUp.graphql';
import { useMutation } from '@apollo/client';
import notifications from 'components/notifications';
import ButtonWithSpiner from 'components/elements/buttonWithSpiner';
import history from 'helpers/history';
import { accountPath } from 'helpers/routes';

export default React.memo(
  ({
    handleOnClick = () => {},
    callbackAfterSignUp = () => {},
    size = 'sm'
  }) => {
    const { t } = useTranslation();
    const [signInFunc, { loading }] = useMutation(signUpMutation, {
      update(cache, { data }) {
        const {
          userSignUp: { authenticatable, message }
        } = data;
        notifications({ message });
        cache.modify({
          fields: {
            isLoggedIn: () => true,
            currentUser: () => ({ ...authenticatable, __typename: 'User' })
          }
        });
        const { role } = authenticatable;
        if (role !== 'admin') history.push(accountPath());
        callbackAfterSignUp();
      },
      onError: () => {}
    });
    const callback = () => {
      signInFunc({ variables: { ...inputs } });
    };
    const { inputs, handleInputChange, handleSubmit } = useForm(
      { email: '' },
      callback
    );

    return (
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <input
            className={`form-control form-control-${size}`}
            type="text"
            name="email"
            placeholder="Email"
            value={inputs.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-2">
          <ButtonWithSpiner
            type="submit"
            loading={loading}
            labelLoading={t('buttons.signUping')}
            label={t('buttons.signUp')}
            className={`btn btn-${size} btn-kirkova`}
            disabled={inputs.email === '' || loading}
          />
        </div>
      </form>
    );
  }
);
