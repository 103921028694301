import React from 'react';
import { whirlwindOfEnergiesPath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/thoughtTherapy/whirlwindOfEnergies')
);
export default {
  path: whirlwindOfEnergiesPath(),
  exact: true,
  strict: true,
  component: component
};
