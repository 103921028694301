import React from 'react';
import { accountPersonalPath } from 'helpers/routes';
const component = React.lazy(() => import('components/views/account/personal'));
export default {
  path: accountPersonalPath(),
  exact: true,
  strict: true,
  component: component,
  protected: true,
  role: ['user']
};
