import React from 'react';
import { orderPath } from 'helpers/routes';
const component = React.lazy(() => import('components/views/order'));
export default {
  path: orderPath(),
  exact: true,
  strict: true,
  component: component,
  protected: true
};
