import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import Link from 'helpers/Link';
import { articlesDetailsPath } from 'helpers/routes';
import query from 'graphql/queries/inspirations/recentInspirations.graphql';

const Item = React.memo(({ item: { content, permalink } }) => {
  const imageContent = content.find(el => el.type === 'image');
  return (
    <div className="col-12 col-md-6 p-1">
      <Link to={articlesDetailsPath(permalink)}>
        <img
          src={imageContent?.url?.replace('192.168.100.9', 'localhost')}
          alt=""
        />
      </Link>
    </div>
  );
});

export default React.memo(() => {
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: { recent: true }
  });
  const [inspirations, setInspirations] = useState([]);
  useEffect(() => {
    if (data?.recentInspirations) {
      setInspirations(data.recentInspirations);
    }
  }, [data]);

  return (
    <div className="col-md-2 col-sm-6 col-xs-12 padding-bottom-10px md-no-border-right sm-text-center xs-no-border-left xs-no-padding-left xs-no-padding-bottom">
      <p className="text-extra-large text-weight-800 text-gray-light">Статьи</p>
      {inspirations.length > 0 ? (
        <div className="d-flex flex-wrap">
          <Item item={inspirations[0] || {}} />
          <Item item={inspirations[1] || {}} />
          <Item item={inspirations[2] || {}} />
          <Item item={inspirations[3] || {}} />
        </div>
      ) : null}
    </div>
  );
});
