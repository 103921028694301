import { library } from '@fortawesome/fontawesome-svg-core';

import {
  // faBold,
  // faItalic,
  // faUnderline,
  // faCode,
  // faRubleSign,
  // faQuoteRight,
  // faListOl,
  // faListUl,
  // faHeading,
  // faVideo,
  // faLink,
  // faImage,
  // faFileAudio,
  // faMusic,
  // faAddressBook,
  // faComments,
  // faPaperPlane,
  // faMicrophone,
  // faFileImage,
  // faArrowAltCircleLeft,
  // faCreditCard,
  // faBars,
  // faBook
  fas
} from '@fortawesome/free-solid-svg-icons';

import {
  // faConnectdevelop,
  // faTelegram,
  // faYoutube,
  // faFacebookF,
  // faInstagram
  fab
} from '@fortawesome/free-brands-svg-icons';

import {
  // faUser,
  // faComments as farComments,
  // faTimesCircle as farTimesCircle,
  // faFile as farFile
  far
} from '@fortawesome/free-regular-svg-icons';

library.add(
  far,
  fab,
  fas
  // faBold,
  // faItalic,
  // faUnderline,
  // faCode,
  // faRubleSign,
  // faQuoteRight,
  // faListOl,
  // faListUl,
  // faHeading,
  // faVideo,
  // faLink,
  // faImage,
  // faFileAudio,
  // faMusic,
  // faAddressBook,
  // faComments,
  // faPaperPlane,
  // faMicrophone,
  // faFileImage,
  // faConnectdevelop,
  // faUser,
  // farComments,
  // faArrowAltCircleLeft,
  // farTimesCircle,
  // farFile,
  // faTelegram,
  // faYoutube,
  // faFacebookF,
  // faInstagram,
  // faCreditCard,
  // faBars,
  // faBook
);
