import React from 'react';
import { newRealityModuleTwoPath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/newReality/modules/secondModule')
);
export default {
  path: newRealityModuleTwoPath(),
  exact: true,
  strict: true,
  component: component
};
