import React, { Suspense, useEffect } from 'react';
import { Router } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { useQuery, useApolloClient } from '@apollo/client';

import ErrorBoundary from 'components/elements/airbrake';
import Loading from 'components/loading';
import history from 'helpers/history';
import ScrollToTop from 'helpers/scrollToTop';
import RoutesComponent from './RoutesComponent';

import isUserLoggedInQuery from 'graphql/queries/auth/isUserLoggedIn.graphql';
import subscriptionQuery from 'graphql/queries/messenger/newMessageReceivedSubscription.graphql';
import receivedMessageQuery from 'graphql/queries/messenger/receivedMessage.graphql';

import 'react-notifications-component/dist/theme.css';

import './icons';

const App = () => {
  const client = useApolloClient();
  const { data } = useQuery(isUserLoggedInQuery);

  useEffect(() => {
    let subscription;
    if (data?.isLoggedIn) {
      const observer = client.subscribe({
        query: subscriptionQuery,
        variables: { uuid: data?.currentUser?.uuid }
      });
      subscription = observer.subscribe(() => {
        client.writeQuery({
          query: receivedMessageQuery,
          data: { receivedMessage: true }
        });
      });
    }

    return () => {
      if (subscription) return subscription.unsubscribe();
    };
  }, [client, data]);

  return (
    <>
      <ReactNotification />
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          <ErrorBoundary>
            <ScrollToTop />
            <RoutesComponent />
          </ErrorBoundary>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
