import React from 'react';

import Motivations from './motivations';
import Link from 'helpers/Link';
import {
  rootPath,
  aboutPath,
  messengerPath,
  termsOfUsePath,
  privacyPolicyPath,
  contactPath,
  accountPath,
  articlesPath
} from 'helpers/routes';

const year = new Date().getFullYear();

const FooterChat = React.memo(() => {
  return (
    <footer className="flex-shrink-1">
      <div className="row bg-black padding-top-bottom-10px padding-left-right-twelve-percent">
        <div className="col-md-8 col-sm-12 col-xs-12">
          <p className="mb-0 text-small text-weight-400 text-gray-light sm-text-center">
            {`Copyright © ${year} of `}
            <a
              href="https://ds.agency"
              target="_blank"
              rel="noopener noreferrer"
            >
              ds.agency.{' '}
            </a>
            <span className="text-gray-light">All rights reserved.</span>
          </p>
        </div>
      </div>
    </footer>
  );
});

export default React.memo(props => {
  return (
    <>
      {messengerPath() === window.location.pathname ? (
        <FooterChat />
      ) : (
        <footer>
          <div className="row bg-gray-extra-dark padding-left-right-twelve-percent padding-top-bottom-150px">
            <div className="col-md-3 col-sm-6 col-xs-12 md-margin-bottom-100px sm-text-center">
              <Link
                to={rootPath()}
                className="margin-bottom-25px display-inline-block"
                data-uk-scroll=""
                style={{ color: '#fff', fontSize: '2rem' }}
              >
                Евгения Киркова
              </Link>
              <p className="margin-bottom-25px text-small-custom text-gray-light text-weight-400 width-90 md-width-100">
                Я живу на Маврикии и помогаю людям находить себя и осуществлять
                мечты, используя ресурсы своей души и тела.
              </p>
              <Link
                to={termsOfUsePath()}
                className="text-small-custom text-weight-400 text-gray-light border-1 border-bottom padding-bottom-10px"
              >
                Пользовательское соглашение
              </Link>
              <div className=" margin-top-bottom-10px" />
              <Link
                className="text-small-custom text-weight-400 text-gray-light"
                to={privacyPolicyPath()}
              >
                Политика конфиденциальности
              </Link>
            </div>
            <div className="col-md-2 col-sm-6 col-xs-12 padding-left-35px md-margin-bottom-100px sm-text-center xs-no-border-left xs-no-padding-left">
              <p className="text-extra-large text-weight-800 text-gray-light">
                Контакты
              </p>
              <ul className="list-unstyled mt-0 mb-0">
                <li className="text-small-custom text-gray-light">
                  evgeniya@kirkova.com
                </li>
              </ul>
            </div>

            <div className="col-md-2 col-sm-6 col-xs-12 padding-left-35px md-margin-bottom-100px sm-text-center xs-no-border-left xs-no-padding-left">
              <p className="text-extra-large text-weight-800 text-gray-light">
                Ссылки
              </p>
              <ul className="list-unstyled mt-0 mb-0">
                <li>
                  <Link
                    className="text-small-custom text-gray-light"
                    to={rootPath()}
                  >
                    Главная
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-small-custom text-gray-light"
                    to={articlesPath()}
                  >
                    Статьи
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-small-custom text-gray-light"
                    to={accountPath()}
                  >
                    Личный кабинет
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-6 col-xs-12 padding-left-35px md-margin-bottom-100px sm-text-center xs-no-border-left xs-no-padding-left">
              <p className="text-extra-large text-weight-800 text-gray-light">
                Соц сети
              </p>
              <ul className="list-unstyled mt-0 mb-0">
                <li>
                  <a
                    className="text-small-custom text-gray-light"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/evgeniya.kirkova"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    className="text-small-custom text-gray-light"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/evgeniyakirkova"
                  >
                    Телеграм
                  </a>
                </li>
                <li>
                  <a
                    className="text-small-custom text-gray-light"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UCs3IcOAKzYxb6avilIkpWHQ"
                  >
                    YouTube
                  </a>
                </li>
                <li>
                  <a
                    className="text-small-custom text-gray-light"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/evgeniya.kirkova.35"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
            <Motivations />
          </div>
          <div className="row bg-black padding-top-bottom-50px padding-left-right-twelve-percent">
            <div className="col-md-8 col-sm-12 col-xs-12">
              <p className="mb-0 text-small text-weight-400 text-gray-light sm-text-center sm-margin-bottom-20px">
                {`Copyright © ${year} of `}{' '}
                <a
                  href="https://ds.agency"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  ds.agency{' '}
                </a>{' '}
                <span className="text-gray-light">All rights reserved.</span>
              </p>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
              {5 === 6 ? (
                <ul className="list-unstyled text-right mb-0 sm-text-center">
                  <li className="display-inline-block margin-right-25px"></li>
                  <li className="display-inline-block margin-right-25px"></li>
                  <li className="display-inline-block margin-right-25px"></li>
                  <li className="display-inline-block"></li>
                </ul>
              ) : null}
            </div>
          </div>
        </footer>
      )}
    </>
  );
});

// <li className="display-inline-block margin-right-25px"><a className="text-medium text-weight-700" href="#"><i className="fab fa-facebook"></i></a></li>
// <li className="display-inline-block margin-right-25px"><a className="text-medium text-weight-700" href="#"><i className="fab fa-twitter"></i></a></li>
// <li className="display-inline-block margin-right-25px"><a className="text-medium text-weight-700" href="#"><i className="fab fa-behance"></i></a></li>
// <li className="display-inline-block margin-right-25px"><a className="text-medium text-weight-700" href="#"><i className="fab fa-dribbble"></i></a></li>
// <li className="display-inline-block"><a className="text-medium text-weight-700" href="#" data-uk-scroll data-uk-tooltip="title: Go to top; pos: right"><i className="far fa-arrow-alt-circle-up"></i></a></li>
