import React from 'react';
import { bookPath } from 'helpers/routes';
const component = React.lazy(() => import('components/views/about'));

export default {
  path: bookPath(),
  exact: true,
  strict: true,
  component: component
};
