import React, { createContext, useContext, useMemo } from 'react';
import useMedia from 'use-media';

export const MediaQueryContext = createContext(null);

const mediaQueries = {
  mobile: '(max-width: 768px)',
  prefersReducedMotion: '(prefers-reduced-motion: reduce)'
};

export default function MediaQueryProvider({ children }) {
  const mobileView = useMedia(mediaQueries.mobile);
  const prefersReducedMotion = useMedia(mediaQueries.prefersReducedMotion);
  const value = useMemo(() => ({ mobileView, prefersReducedMotion }), [
    mobileView,
    prefersReducedMotion
  ]);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}
