import React from 'react';
import Link from 'helpers/Link';
import { rootPath } from 'helpers/routes';

const a = () => {
  return (
    <div className="padding-top-bottom-120px">
      <div className="container-spread">
        <div className="row">
          <div className="col-md-10 col-sm-12 col-xs-12 text-left">
            <h2 className="mb-0 text-weight-200 text-gray-extra-dark">
              У вас нет доступа к этой
            </h2>
            <h2 className="mb-0 text-weight-800 text-gray-extra-dark">
              странице.
            </h2>
            <div className="separator bottom-border border-1px border-color-gray-light width-10 margin-top-bottom-50px"></div>
            <h6 className="margin-bottom-35px open-sans text-weight-400 text-gray-extra-dark">
              Ошибка 403
            </h6>
            <Link
              to={rootPath()}
              className="btn btn-extra-large btn-black margin-right-10px sm-display-table xs-margin-bottom-15px sm-margin-left-right-auto"
            >
              Вернуться на главную
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default a;
