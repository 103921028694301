import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { createCache, createClient } from 'helpers/apollo';
import { CachePersistor } from 'apollo3-cache-persist';
import { AUTH_TOKEN } from 'constants/API';
import Loading from 'components/loading';

import currentUserQuery from 'graphql/queries/auth/currentUser.graphql';
import baseQuery from 'graphql/queries/cache/query.graphql';

const SCHEMA_VERSION = '0.1.1';
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

const isLoggedIn = !!localStorage.getItem(AUTH_TOKEN);
export const receivedMessage = false;
export const currentUser = {
  __typename: 'User',
  firstName: null,
  lastName: null,
  email: null,
  confirmedAt: null,
  provider: null,
  role: null,
  nickname: null,
  uuid: null,
  avatar: null,
  birthday: null
};

const asyncLocalStorage = {
  setItem: async function(key, value) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function(key) {
    await null;
    return localStorage.getItem(key);
  }
};

async function setupApollo({ setClient }) {
  const cache = createCache();
  const client = createClient(cache);
  const persistor = new CachePersistor({
    cache,
    storage: localStorage
  });

  const currentVersion = await asyncLocalStorage.getItem(SCHEMA_VERSION_KEY);

  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore();
    try {
      client.readQuery({ query: currentUserQuery });
    } catch (e) {
      client.writeQuery({
        query: baseQuery,
        data: { isLoggedIn: false, currentUser, receivedMessage }
      });
    }
    client.onResetStore(async () => {
      client.writeQuery({
        query: baseQuery,
        data: { isLoggedIn: false, currentUser, receivedMessage }
      });
    });
    setClient(client);
  } else {
    localStorage.removeItem(AUTH_TOKEN);
    await persistor.purge();
    await asyncLocalStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    await client.writeQuery({
      query: baseQuery,
      data: { isLoggedIn: false, currentUser, receivedMessage }
    });
    setClient(client);
  }
}

export const initData = { isLoggedIn, currentUser, receivedMessage };

export default ({ children }) => {
  const [client, setClient] = useState(undefined);

  useEffect(() => {
    setupApollo({ setClient });
    return () => {};
  }, []);

  if (client === undefined) return <Loading />;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
