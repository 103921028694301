import React from 'react';
import { rootPath } from 'helpers/routes';
const component = React.lazy(() => import('components/views/main'));

export default {
  path: rootPath(),
  exact: true,
  strict: true,
  component: component
};
