import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGA4React } from 'ga-4-react';

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function useGoogleAnalytics() {
  const location = useLocation();
  const ga4react = useGA4React('G-QC0NQS8CGV', { debug: isDev });

  React.useEffect(() => {
    if (ga4react) {
      const currentPath = location.pathname + location.search;
      ga4react.pageview(currentPath);
    }
  }, [ga4react, location]);
}
