import React from 'react';
import { orderBOPath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/backOffice/orders/show')
);
const layout = React.lazy(() => import('components/adminLayout'));
export default {
  layout,
  path: orderBOPath(),
  exact: true,
  strict: true,
  component: component,
  protected: true,
  role: ['admin', 'superAdmin']
};
