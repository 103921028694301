import React from 'react';
import { newRealityModuleThreePath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/newReality/modules/thirdModule')
);
export default {
  path: newRealityModuleThreePath(),
  exact: true,
  strict: true,
  component: component
};
