import rootPath from './root';
import cleanPath from './clean';
import logoutPath from './logout';
import loginPath from './login';
import aboutPath from './about';
import purposePath from './purpose';
import bookPath from './book';
import privatePolicy from './privatePolicy';
import termsOfUse from './termsOfUse';
import inspiration from './inspiration';
import inspirationDetail from './inspiration/show';
import articles from './articles';
import articlesDetails from './articles/show';
import backOfficePath from './backoffice';
import usersBOPath from './backoffice/users';
import userBOPath from './backoffice/users/show';
import userBOEditPath from './backoffice/users/update';
import accountPath from './account';
import accountPersonalPath from './account/personal';
import servicesBOPath from './backoffice/services';
import serviceBONewPath from './backoffice/services/new';
import serviceBOEditPath from './backoffice/services/update';
import serviceBOPath from './backoffice/services/show';
import messengerPath from './messenger';
import staticPagesPath from './backoffice/staticPages';
import staticPageNewPath from './backoffice/staticPages/add';
import staticPagePath from './backoffice/staticPages/show';
import staticPageEditPath from './backoffice/staticPages/update';
import comingSoonPath from './comingSoon';
import notFound from './notFound';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import loginNewReality from './loginNewReality';
import loginNewRealityFree from './loginNewRealityFree';
import loginSubconscious from './loginSubconscious';
import serviceClientPath from './services/show';
import orderPath from './order';
import exerciseClientPath from './services/exercises/show';
import contactPath from './contacts';
import sessionWithSubconsciousPath from './sessionWithSubconscious';
import newReality from './newReality';
import newRealityFirstModule from './newReality/modules/first';
import newRealitySecondModule from './newReality/modules/second';
import newRealityThirdModule from './newReality/modules/third';
import queensMove from './queensMove';
import inspirationBO from './backoffice/inspiration';
import inspirationNew from './backoffice/inspiration/new';
import inspirationEdit from './backoffice/inspiration/update';
import inspirationView from './backoffice/inspiration/show';
import categoryInspirations from './backoffice/categoryInspirations';
import categoryInspirationsNew from './backoffice/categoryInspirations/new';
import categoryInspirationEditPath from './backoffice/categoryInspirations/update';
import settingsBO from './backoffice/settings';
import soulChoice from './soulChoice';
import mentoring from './mentoring';
import retreat from './retreat';

import ordersBOPath from './backoffice/orders';
import orderBOPath from './backoffice/orders/show';

import serviceStepsPath from './backoffice/services/steps';
import serviceStepEditPath from './backoffice/services/steps/update';
import serviceStepNewPath from './backoffice/services/steps/add';

import leads from './backoffice/leads';
import leadNew from './backoffice/leads/add';
import leadEdit from './backoffice/leads/update';

import feedbacks from './backoffice/feedbacks';
import feedbackNew from './backoffice/feedbacks/add';
import feedbackEdit from './backoffice/feedbacks/update';

import vortex from './vortex';
import whirlwindOfEnergies from './whirlwindOfEnergies';

import familyLine from './familyLine';
import therapy from './therapy';
import dreams from './dreams';
import webinars from './webinars';

export default [
  vortex,
  privatePolicy,
  termsOfUse,
  rootPath,
  logoutPath,
  loginPath,
  contactPath,
  aboutPath,
  purposePath,
  queensMove,
  backOfficePath,
  accountPath,
  accountPersonalPath,
  usersBOPath,
  userBOPath,
  userBOEditPath,
  servicesBOPath,
  serviceBONewPath,
  serviceBOEditPath,
  serviceBOPath,
  messengerPath,
  staticPagesPath,
  staticPageNewPath,
  staticPagePath,
  staticPageEditPath,
  comingSoonPath,
  exerciseClientPath,
  resetPassword,
  forgotPassword,
  notFound,
  loginNewReality,
  loginNewRealityFree,
  serviceClientPath,
  sessionWithSubconsciousPath,
  loginSubconscious,
  bookPath,
  newReality,
  newRealityFirstModule,
  newRealitySecondModule,
  newRealityThirdModule,
  inspiration,
  inspirationDetail,
  inspirationBO,
  inspirationNew,
  inspirationEdit,
  inspirationView,
  categoryInspirations,
  categoryInspirationsNew,
  settingsBO,
  orderPath,
  ordersBOPath,
  orderBOPath,
  serviceStepsPath,
  serviceStepEditPath,
  serviceStepNewPath,
  categoryInspirationEditPath,
  leads,
  leadNew,
  leadEdit,
  whirlwindOfEnergies,
  feedbacks,
  feedbackNew,
  feedbackEdit,
  cleanPath,
  familyLine,
  therapy,
  dreams,
  articles,
  articlesDetails,
  webinars,
  mentoring,
  soulChoice,
  retreat
];
