import React from 'react';
import { loginSubconsciousPath } from 'helpers/routes';
const component = React.lazy(() =>
  import('components/views/loginSubconscious')
);
export default {
  path: loginSubconsciousPath(),
  exact: true,
  strict: true,
  component: component
};
