import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
// import StoreContext from 'storeon/react/context';

import { SENTRY_DSN } from 'constants/sentry';
import MediaQueryProvider from 'components/MediaQueryProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Provider from 'components/provider';
// import { store } from 'store/index';

import 'i18n';

const { version } = require('../package.json');

Sentry.init({
  dsn: SENTRY_DSN,
  release: version,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <MediaQueryProvider>
    <Provider>
      <App />
    </Provider>
  </MediaQueryProvider>,
  document.querySelector('#root')
);

serviceWorker.unregister();
