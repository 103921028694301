import {
  // aboutPath,
  // bookPath,
  accountPath,
  // contactPath,
  backOfficePath,
  // sessionWithSubconsciousPath,
  // whoAmIPath,
  // queensMovePath,
  newRealityPath,
  // soulChoicePath,
  // mentoringPath,
  // retreatPath,
  // newRealityModuleOnePath,
  // newRealityModuleTwoPath,
  // newRealityModuleThreePath,
  // newRealityModuleFourPath,
  // bookAboutYourBullshitPath,
  // whirlwindOfEnergiesPath,
  messengerPath,
  // purposePath,
  // articlesPath,
  // familyLinePath,
  // therapyPath,
  // dreamsPath,
  // webinarsPath
} from 'helpers/routes';

// const newReality = [
//   {
//     path: newRealityModuleOnePath,
//     label: 'mainMenu.newRealityModuleOne',
//     main: true,
//     mobile: true
//   },
//   {
//     path: newRealityModuleTwoPath,
//     label: 'mainMenu.newRealityModuleTwo',
//     main: true,
//     mobile: true
//   },
//   {
//     path: newRealityModuleThreePath,
//     label: 'mainMenu.newRealityModuleThree',
//     main: true,
//     mobile: true
//   },
//   {
//     path: newRealityModuleFourPath,
//     label: 'mainMenu.newRealityModuleFour',
//     main: true,
//     mobile: true
//   },
//   {
//     divider: true
//   },
//   {
//     path: newRealityPath,
//     label: 'mainMenu.newRealityCourse',
//     main: true,
//     mobile: true
//   }
// ];

const thoughtTherapy = [
  // {
  //   path: purposePath,
  //   label: 'mainMenu.searchForWay',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: queensMovePath,
  //   label: 'mainMenu.queensMove',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: soulChoicePath,
  //   label: 'mainMenu.soulChoice',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: sessionWithSubconsciousPath,
  //   label: 'mainMenu.sessionWithSubconscious',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: dreamsPath,
  //   label: 'mainMenu.dreams',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: mentoringPath,
  //   label: 'mainMenu.mentoring',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: retreatPath,
  //   label: 'mainMenu.retreat',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: familyLinePath,
  //   label: 'mainMenu.familyLine',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   divider: true
  // },
  // {
  //   path: articlesPath,
  //   label: 'mainMenu.articles',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   path: webinarsPath,
  //   label: 'mainMenu.webinars',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   divider: true
  // },
  // {
  //   path: bookPath,
  //   label: 'mainMenu.book',
  //   main: true,
  //   mobile: true
  // }
];

export default [
  // { path: aboutPath, label: 'mainMenu.about', main: true, mobile: true },
  // {
  //   label: 'mainMenu.newReality',
  //   main: true,
  //   mobile: true,
  //   path: newRealityPath,
  // },
  // {
  //   path: therapyPath,
  //   label: 'mainMenu.therapy',
  //   main: true,
  //   mobile: true
  // },
  // {
  //   label: 'mainMenu.thoughtTherapy',
  //   main: true,
  //   mobile: true,
  //   children: thoughtTherapy
  // },
  {
    path: accountPath,
    label: 'mainMenu.account',
    main: true,
    mobile: true,
    userRole: ['user'],
    cssClass: 'custom-nav-class'
  },
  {
    path: backOfficePath,
    label: 'navMenu.backoffice',
    main: true,
    mobile: true,
    userRole: ['admin', 'superAdmin']
  },
  {
    path: messengerPath,
    label: 'mainMenu.messenger',
    main: true,
    mobile: true,
    userRole: ['user', 'admin', 'superAdmin']
  }
];
