import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { loginPath } from 'helpers/routes';
import userQuery from 'components/auth/isUserLoggedIn.graphql';
import { useQuery } from '@apollo/client';
import Page403 from 'components/403';

const Layout = React.lazy(() => import('components/layout'));

const SwithByRoute = React.memo(({ route, props }) => {
  const { data = {} } = useQuery(userQuery);
  const { isLoggedIn = false, currentUser = {} } = data;
  const { role } = currentUser;

  if (route.protected && isLoggedIn !== true) {
    return (
      <Redirect
        to={{ pathname: loginPath(), state: { from: props.location } }}
      />
    );
  }
  if (route.role && !route.role.includes(role)) return <Page403 {...props} />;
  return <route.component {...props} />;
});

export default React.memo(route => {
  const CurentLayout = route.layout ? route.layout : Layout;
  return (
    <CurentLayout>
      <Route
        path={route.path}
        render={props => <SwithByRoute route={route} props={props} />}
      />
    </CurentLayout>
  );
});
