export const rootPath = () => '/';
export const cleanPath = () => '/clean';
export const bookPath = () => '/bookkehoe';
export const logoutPath = () => '/logout';
export const loginPath = () => '/login';
export const aboutPath = () => '/about';
export const newRealityPath = () => '/newreality';
export const newRealityModuleOnePath = () => '/newreality/module1-mindpower';
export const newRealityModuleTwoPath = () => '/newreality/module2-lifebalance';
export const newRealityModuleThreePath = () => '/newreality/module3-generation';
export const newRealityModuleFourPath = () => '/newreality/module4';
export const familyLinePath = () => '/thought-therapy/family-line';
export const gamePath = () => '/game';
export const contactPath = () => '/contacts';
export const inspirationPath = () => '/inspiration';
export const inspirationDetailPath = (permalink = ':permalink') =>
  `/inspiration/${permalink}`;

export const articlesPath = () => '/articles';
export const articlesDetailsPath = (permalink = ':permalink') =>
  `/articles/${permalink}`;

export const accountPath = () => '/account';
export const accountPersonalPath = () => '/account/personal';

export const purposePath = () => '/thought-therapy/purpose';
export const sessionWithSubconsciousPath = () =>
  '/thought-therapy/subconscious';
export const whoAmIPath = () => '/thought-therapy/who-am-i';
export const queensMovePath = () => '/thought-therapy/queensmove';
export const bookAboutYourBullshitPath = () => '/thought-therapy/book';
export const whirlwindOfEnergiesPath = () =>
  '/thought-therapy/whirlwind-of-energies';
export const soulChoicePath = () => '/thought-therapy/soulchoice';
export const mentoringPath = () => '/thought-therapy/mentoring';

export const messengerPath = () => '/chat';
export const comingSoonPath = () => '/coming-soon';
export const notFoundPath = () => '/not-found';
export const forgotPasswordPath = () => '/forgot-password';
export const resetPasswordPath = () => '/reset-password';
export const resetPasswordForOrderPath = (serviceId = ':serviceId') =>
  `/reset-password?service_id=${serviceId}`;
export const loginNewRealityPath = () => '/loginnewreality';
export const loginNewRealityFreePath = () => '/loginnewreality-free';
export const loginSubconsciousPath = () => '/loginsubconscious';
export const serviceAccountPath = (id = ':id') => `/services/${id}`;
export const exercisePath = (id = ':id', serviceId = ':serviceId') =>
  `/services/${serviceId}/exercises/${id}`;
export const orderPath = (id = ':id') => `/orders/${id}`;
export const termsOfUsePath = () => '/terms-of-use';
export const privacyPolicyPath = () => '/privacy-policy';
export const vortexPublicPath = (id = ':id') => `/vortex/${id}`;

export const backOfficePath = () => '/backoffice';
export const settingsBOPath = () => '/backoffice/settings';

export const inspirationBOPath = () => '/backoffice/inspirations';
export const inspirationEditPath = (id = ':id') =>
  `/backoffice/inspirations/${id}/edit`;
export const inspirationNewPath = () => '/backoffice/inspirations/new';
export const inspirationViewPath = (id = ':id') =>
  `/backoffice/inspirations/${id}`;
export const categoryInspirationsPath = () =>
  '/backoffice/category-inspirations';
export const categoryInspirationNewPath = () =>
  '/backoffice/category-inspirations/new';
export const categoryInspirationEditPath = (id = ':id') =>
  `/backoffice/category-inspirations/${id}/edit`;

export const usersPath = () => '/backoffice/users';
export const userEditPath = (id = ':id') => `/backoffice/users/${id}/edit`;
export const userPath = (id = ':id') => `/backoffice/users/${id}`;

export const servicesPath = () => '/backoffice/services';
export const serviceEditPath = (id = ':id') =>
  `/backoffice/services/${id}/edit`;
export const servicePath = (id = ':id') => `/backoffice/services/${id}`;
export const serviceStepsPath = (id = ':id') =>
  `/backoffice/services/${id}/steps`;
export const serviceNewPath = () => '/backoffice/services/new';
export const serviceStepNewPath = (serviceId = ':serviceId') =>
  `/backoffice/services/${serviceId}/steps/new`;
export const serviceStepEditPath = (serviceId = ':serviceId', id = ':id') =>
  `/backoffice/services/${serviceId}/steps/${id}/edit`;

export const staticPagesPath = () => '/backoffice/static-pages';
export const staticPageEditPath = (id = ':id') =>
  `/backoffice/static-pages/${id}/edit`;
export const staticPagePath = (id = ':id') => `/backoffice/static-pages/${id}`;
export const staticPageNewPath = () => '/backoffice/static-pages/new';

export const leadsPath = () => '/backoffice/leads';
export const leadEditPath = (id = ':id') => `/backoffice/leads/${id}/edit`;
export const leadPath = (id = ':id') => `/backoffice/leads/${id}`;
export const leadNewPath = () => '/backoffice/leads/new';

export const feedbacksPath = () => '/backoffice/feedbacks';
export const feedbackEditPath = (id = ':id') =>
  `/backoffice/feedbacks/${id}/edit`;
export const feedbackPath = (id = ':id') => `/backoffice/feedbacks/${id}`;
export const feedbackNewPath = () => '/backoffice/feedbacks/new';

export const orderBOPath = (id = ':id') => `/backoffice/orders/${id}`;
export const ordersBOPath = () => '/backoffice/orders';

export const therapyPath = () => '/therapy';

export const dreamsPath = () => '/thought-therapy/dreams';
export const webinarsPath = () => '/webinars';
export const retreatPath = () => '/thought-therapy/retreat';
