import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navbar,
  NavbarToggler,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMediaQueryContext } from 'components/MediaQueryProvider';
import DropDownAuthMenu, { AuthMenu } from './dropDownAuthMenu';
import Link from 'helpers/Link';
import { rootPath, messengerPath } from 'helpers/routes';
import schemaMenu from 'helpers/schemaMenu';
import query from 'graphql/queries/messenger/newMessages.graphql';

const HoverDropdown = React.memo(props => {
  const { defaultOpen, ...toPassProps } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const handleOnMouseEnter = () => setIsOpen(true);
  const handleOnMouseLeave = () => setIsOpen(false);

  return (
    <Dropdown
      {...toPassProps}
      isOpen={isOpen}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      toggle={() => setIsOpen(!isOpen)}
    />
  );
});

const NavMenuItem = React.memo(
  ({
    main,
    path,
    label,
    isLoggedIn,
    userRole,
    role,
    children,
    receivedMessage,
    cssClass = '',
    ...props
  }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { mobileView } = useMediaQueryContext();
    if (
      !main ||
      (userRole && !isLoggedIn) ||
      (isLoggedIn && userRole && !userRole.includes(role))
    )
      return null;
    return (
      <>
        {path && (
          <li className="nav-item d-md-flex justify-content-md-start align-items-md-center">
            <Link
              to={path()}
              disabled={children && children.length > 0}
              className={`nav-link text-gray-extra-light text-extra-small ${cssClass}`}
            >
              {t(label)}
            </Link>
            {messengerPath() === path() &&
              location.pathname !== path() &&
              receivedMessage &&
              isLoggedIn && (
                <span className="m-messenger__conversation-new-messages-amount-custom"></span>
              )}
          </li>
        )}
        {!path && (
          <HoverDropdown
            nav
            inNavbar
            className="d-md-flex justify-content-md-start align-items-md-center"
          >
            <DropdownToggle nav caret={mobileView}>
              {t(label)}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-custom">
              <ul className="pl-0">
                {children &&
                  children.map((child, index) => (
                    <li key={`sub-menu-${index}`}>
                      {child.divider ? (
                        <>
                          <DropdownItem divider />
                        </>
                      ) : (
                        <Link to={child.path()} className="dropdown-item">
                          {t(child.label)}
                        </Link>
                      )}
                    </li>
                  ))}
              </ul>
            </DropdownMenu>
          </HoverDropdown>
        )}
      </>
    );
  }
);

export default React.memo(({ isLoggedIn, handleSignOut, role, ...props }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    data: { receivedMessage }
  } = useQuery(query);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { mobileView } = useMediaQueryContext();

  return (
    <>
      <Navbar className="navbar-custom-user px-2" expand="md" fixed="top">
        <Link to={rootPath()} className="nav-logo">
          {t('navMenu.logoText')}
        </Link>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon="bars" />
          {messengerPath() !== location.pathname &&
            receivedMessage &&
            isLoggedIn && (
              <span className="m-messenger__conversation-new-messages-amount-custom-navbar"></span>
            )}
        </NavbarToggler>
        <div
          className={`navbar-collapse offcanvas-collapse px-3 ${
            isOpen ? 'open' : ''
          }`}
        >
          <ul className="ml-auto navbar-nav text-weight-600 mr-4">
            {schemaMenu.map((menu, index) => (
              <NavMenuItem
                key={`main-menu-${index}`}
                {...menu}
                isLoggedIn={isLoggedIn}
                role={role}
                receivedMessage={receivedMessage}
              />
            ))}
          </ul>
          {!mobileView && (
            <DropDownAuthMenu
              label={isLoggedIn ? t('buttons.signOut') : t('buttons.signIn')}
              handleSignOut={handleSignOut}
              isLoggedIn={isLoggedIn}
            />
          )}
          {mobileView && (
            <>
              {isLoggedIn ? (
                <button
                  className="btn btn-sm btn-kirkova-white"
                  type="button"
                  onClick={handleSignOut}
                >
                  {t('buttons.signOut')}
                </button>
              ) : (
                <AuthMenu />
              )}
            </>
          )}
        </div>
      </Navbar>
      <div className="sticky-placeholder" />
    </>
  );
});

// <Collapse
//   isOpen={isOpen}
//   navbar
//   className="padding-right-one-percent offcanvas-collapse"
// >
