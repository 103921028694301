import React from 'react';
import Navbar from 'components/layout/navbar';
import Footer from 'components/footer';
import query from 'components/auth/isUserLoggedIn.graphql';
import { useMutation, useApolloClient } from '@apollo/client';
import operations from 'components/auth/signOut/operations.graphql';
import { AUTH_TOKEN } from 'constants/API';
import notifications from 'components/notifications';
import Div100vh from 'react-div-100vh';
import history from 'helpers/history';
import { logoutPath } from 'helpers/routes';

import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'uikit';

import 'uikit/dist/css/uikit.css';
import 'theme/style.css';
import 'theme/responsive.css';
import 'theme/responsive-custom.scss';

import 'theme/index.css';
import 'theme/style.scss';

export default React.memo(({ children }) => {
  const client = useApolloClient();
  const {
    isLoggedIn = false,
    currentUser: { role }
  } = client.readQuery({ query });

  const [signOut] = useMutation(operations, {
    update(
      cache,
      {
        data: {
          userLogout: { message }
        }
      }
    ) {
      history.push(logoutPath());
      localStorage.removeItem(AUTH_TOKEN);
      notifications({ message });
    },
    onError: () => {
      history.push(logoutPath());
    }
  });

  const handleSignOut = event => {
    event.preventDefault();
    event.stopPropagation();
    if (isLoggedIn) signOut();
  };

  return (
    <Div100vh>
      <div className="d-flex flex-column h-100">
        <div className="flex-fill d-flex flex-column">
          <Navbar
            isLoggedIn={isLoggedIn}
            handleSignOut={handleSignOut}
            role={role}
          />
          {children}
        </div>
        <Footer />
      </div>
    </Div100vh>
  );
});

// <OffCanvas
//   isLoggedIn={isLoggedIn}
//   handleSignOut={handleSignOut}
//   role={role}
// />
