import React from 'react';
import useForm from 'helpers/useForm';
import { useMutation } from '@apollo/client';
import operations from './operations.graphql';
import notifications from 'components/notifications';
import { useTranslation } from 'react-i18next';
import { resetPasswordPath } from 'helpers/routes';

const redirectUrl = `${window.location.origin}${resetPasswordPath()}`;

export default React.memo(({ handleOnClick = () => {}, toogle }) => {
  const { t } = useTranslation();
  const [resetPassFunc] = useMutation(operations, {
    update(
      cache,
      {
        data: {
          userSendPasswordReset: { message }
        }
      }
    ) {
      notifications({ message, duration: 3000 });
      handleOnClick();
      init();
    },
    onError: () => {}
  });
  const callback = () => {
    resetPassFunc({ variables: { ...inputs } });
  };
  const { inputs, handleInputChange, handleSubmit, init } = useForm(
    { email: '', redirectUrl },
    callback
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-2">
        <input
          className="form-control form-control-sm"
          type="text"
          name="email"
          placeholder="Введите email"
          value={inputs.email}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-2">
        <button
          type="submit"
          disabled={inputs.email === ''}
          className="btn btn-sm btn-kirkova mr-2"
        >
          {t('buttons.reset')}
        </button>
        <button
          onClick={toogle(0)}
          type="button"
          className="btn btn-sm btn-kirkova-white"
        >
          {t('buttons.back')}
        </button>
      </div>
    </form>
  );
});
