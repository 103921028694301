import React from 'react';
import { messengerPath } from 'helpers/routes';
import layout from 'components/mesengerLayout';
const component = React.lazy(() => import('components/views/messenger'));

export default {
  layout,
  path: messengerPath(),
  exact: true,
  strict: true,
  component: component,
  protected: true
};
