import React from 'react';

export default ({
  className = 'btn btn-sm btn-secondary',
  type = 'button',
  loading = false,
  label,
  labelLoading,
  disabled = false,
  onClick = () => {}
}) => {
  return (
    <button
      className={className}
      type={type}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading && !disabled ? (
        <>
          <span
            className="spinner-grow spinner-grow-sm mr-1"
            role="status"
            aria-hidden="true"
            style={{ color: '#91d3e1' }}
          />
          {labelLoading}
        </>
      ) : (
        <>{label}</>
      )}
    </button>
  );
};
