import React from 'react';
import { backOfficePath } from 'helpers/routes';
const layout = React.lazy(() => import('components/adminLayout'));
const component = React.lazy(() => import('components/views/backOffice'));
export default {
  layout,
  path: backOfficePath(),
  exact: true,
  strict: true,
  component: component,
  protected: true,
  role: ['admin', 'superAdmin']
};
