import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import useGoogleAnalytics from 'helpers/useGoogleAnalytics';
import RouteWithSubRoutes from 'components/elements/routeWithSubRoutes';
import routes from './routes';
import { comingSoonPath } from 'helpers/routes';

const RoutesComponent = () => {
  useGoogleAnalytics();

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={`route-${i}`} {...route} />
      ))}
      <Redirect to={comingSoonPath()} />
    </Switch>
  );
};

export default RoutesComponent;
