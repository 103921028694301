import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import notifications from 'components/notifications';
import history from 'helpers/history';
import { accountPath } from 'helpers/routes';

import signInMutation from 'graphql/queries/auth/signIn.graphql';

export default ({ handleOnClick = () => {}, toogle }) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const [signInFunc, { loading }] = useMutation(signInMutation, {
    update: (cache, { data }) => {
      const {
        userLogin: { authenticatable, message }
      } = data;
      notifications({ message });
      cache.modify({
        fields: {
          isLoggedIn: () => true,
          currentUser: () => ({ ...authenticatable, __typename: 'User' })
        }
      });
      const { role } = authenticatable;
      if (role !== 'admin') history.push(accountPath());
      handleOnClick();
    },
    onError: () => {}
  });
  const onSubmit = inputsData => {
    signInFunc({ variables: { ...inputsData } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <input
          className={`form-control form-control-sm ${
            errors?.email ? 'is-invalid' : ''
          }`}
          type="text"
          name="email"
          placeholder="email"
          defaultValue=""
          ref={register({ required: 'Это поле обязательное.' })}
        />
      </div>
      <div className="mb-2">
        <input
          className={`form-control form-control-sm ${
            errors?.password ? 'is-invalid' : ''
          }`}
          type="password"
          name="password"
          placeholder="password"
          defaultValue=""
          ref={register({ required: 'Это поле обязательное.' })}
        />
      </div>
      <div className="mb-2">
        <button
          type="submit"
          disabled={loading}
          className="btn btn-kirkova btn-sm mr-1"
        >
          {t('buttons.signIn')}
        </button>
        <button
          onClick={toogle(2)}
          type="button"
          className="btn btn-kirkova-white btn-sm"
        >
          {t('buttons.resetPassword')}
        </button>
      </div>
    </form>
  );
};
