import { useState, useCallback } from 'react';

const index = (obj, is, value) => {
  if (typeof is == 'string') {
    const keys = is.split('.');
    if (keys[0] !== is) return index(obj, is.split('.'), value);
    else obj[is] = value;
    return obj;
  } else if (is.length === 1 && value !== undefined) {
    return value;
  } else if (is.length === 0) {
    return obj;
  } else {
    const key = is.slice(1);
    const rootKey = is[0];
    const result = index({ ...obj[rootKey] }, key, value);
    return { ...obj, [rootKey]: { ...obj[rootKey], [key]: result } };
  }
};

export default (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = useCallback(
    event => {
      if (event) event.preventDefault();
      callback(event);
    },
    [callback]
  );
  const handleInputChange = useCallback(
    event => {
      event.persist();
      let {
        target: { value, type, checked, dataset, name }
      } = event;
      if (type && type === 'checkbox') {
        value = checked;
      } else if (dataset.money) {
        if (value.match(/^[+-]?\d+(\.\d+)?$/)) {
          value *= 100;
        } else {
          value = 0;
        }
      }
      const clone = index({ ...inputs }, name, value);
      setInputs({ ...clone });
    },
    [inputs]
  );

  const handleDataChange = useCallback(object => {
    setInputs(inputs => ({ ...inputs, ...object }));
  }, []);

  const handleDataArrayDecrease = useCallback(
    key => {
      const object = inputs[key];
      object.pop();
      handleDataChange({ [key]: object });
    },
    [handleDataChange, inputs]
  );

  const handleDataArrayIncrease = useCallback(
    key => {
      const object = inputs[key];
      object.push(0);
      handleDataChange({ [key]: object });
    },
    [handleDataChange, inputs]
  );

  const handleArrayField = useCallback((event, key, index) => {
    event.persist();
    setInputs(inputs => {
      inputs[key][index] = {
        ...inputs[key][index],
        [event.target.name]: event.target.value
      };
      return { ...inputs };
    });
  }, []);

  const handleSelectSearch = useCallback(
    (name, value) => {
      const clone = index({ ...inputs }, name, value);
      setInputs({ ...clone });
    },
    [inputs]
  );

  const init = useCallback(
    (values = initialValues) => {
      setInputs(values);
    },
    [initialValues]
  );

  const handleFile = useCallback(event => {
    event.persist();
    const { target } = event;
    const file = target.files[0];
    setInputs(inputs => ({ ...inputs, [target.name]: file }));
  }, []);

  return {
    handleSubmit,
    handleInputChange,
    handleDataChange,
    handleDataArrayDecrease,
    handleDataArrayIncrease,
    handleArrayField,
    handleSelectSearch,
    handleFile,
    inputs,
    setInputs,
    init
  };
};

export const clearValidation = formRef => {
  const form = formRef.current;
  if (form) {
    Array.from(form.elements).forEach(el => {
      if (el) el.setCustomValidity('');
    });
  }
};
